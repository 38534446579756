.scrollbar {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  padding: 24px;

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--chakra-colors-orange);
    border-radius: 17px;
  }

  &::-webkit-scrollbar-track {
    background-color: #232323;
  }

  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: #232323  var(--chakra-colors-orange);
  }
}
