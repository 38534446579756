@import "src/styles/mixins";
@import "src/styles/media";

.container {
  @include custom-scrollbar-vertical;
  width: 100%;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow-y: auto;
  position: relative;
}

.topWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include tablet-landscape {
    flex-direction: row;
  }
}

.header {
  color: var(--chakra-colors-gray-4);
  display: none;
  justify-content: space-between;
  border-radius: 0;
  padding: 24px 12px 12px;
  align-items: center;
  cursor: default;
  font-weight: 400;
  font-size: 14px;
  position: sticky;
  top: 0;
  gap: 16px;
  z-index: 1;

  @include tablet-landscape {
    display: flex;
  }
}

.referralsHeader {
  padding: 0 53px 12px;
}

.row {
  border-radius: 8px;
  border: 1px solid var(--chakra-colors-gray-5);
  background: var(--chakra-colors-black);

  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
  align-items: center;
  gap: 16px;
  margin-bottom: 14px;
  transition: background-color 0.2s ease;

  &:hover, &.open {
    background-color: var(--chakra-colors-neutral-800);
  }

  &.clickable {
    cursor: pointer;
  }
}

.actions {
  visibility: visible;
}

.rowMobile {
  @include mobile-only {
    border-radius: 12px;

    &:nth-of-type(even) {
      background-color: var(--chakra-colors-neutral-800);
    }
  }
}

.aprChevron {
  transform: scale(0.7);
}

.icon {
  color: var(--chakra-colors-gray-4);

  &.active {
    color: var(--chakra-colors-orange);
  }
}

.transferTooltip {
  color: var(--chakra-colors-warning-100) !important;
  font-size: 14px;
  line-height: 1.2;
}
