@import "src/styles/media";

.fadeContainer {
  position: relative;
  height: 60px;
  width: 100%;
  text-align: center;
  overflow: hidden;

  @include tablet-portrait {
    height: 30px;
  }
}

.fadeItem {
  position: absolute;
  width: 100%;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
}

.fadeItem.visible {
  opacity: 1;
  transform: translateY(0);
}

.fadeItem:not(.visible) {
  opacity: 0;
  transform: translateY(-20px);
}
