.root {
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.amount {
  font-size: 14px;
  color: var(--chakra-colors-gray-4);
}
