@import "src/styles/mixins";

.scrollWrapper {
  @include custom-scrollbar-vertical;
}


.verified {
  font-size: 10px;
  color: var(--chakra-colors-success);
  padding: 2px 4px;
  border: 1px solid var(--chakra-colors-success);
  border-radius: 4px;
  margin-left: 8px;
  white-space: nowrap;
}

.verifiedMeme {
  font-size: 10px;
  color: var(--chakra-colors-meme);
  padding: 2px 4px;
  border: 1px solid var(--chakra-colors-meme);
  border-radius: 4px;
  margin-left: 8px;
  white-space: nowrap;
}


.checkbox {
  color: var(--chakra-colors-neutral-300);
  font-size: 14px;

  :global {
    .chakra-checkbox__control {
      width: 24px;
      height: 24px;
      border: 1px solid var(--chakra-colors-neutral-500);
      border-radius: 4px;
    }
  }
}
