.root {
  align-items: center;
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
}

.wrapper {
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  padding: 0 12px;
  width: 100%;
  height: 100%;
  background-color: var(--chakra-colors-neutral-700);

  input {
    flex: 1;
    height: 100%;
    width: 100%;
    background-color: var(--chakra-colors-neutral-700);
  }
}


.iconWrapper {
  margin-left: 8px;
  margin-right: 8px;
  color: var(--chakra-colors-neutral-400);
}
