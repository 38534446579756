.tabs {
  border-bottom: 3px solid var(--chakra-colors-gray-5);
}

.tab {
  color: #FFF;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 118.182% */
  padding: 0 12px 16px;
  min-width: 90px;
  width: 100%;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.coinIcon {
  width: 16px;
  height: 10px;
  margin-left: 4px;
  margin-top: 4px;
  display: none;
}

.widgetsWrapper {
  display: grid;
  flex-wrap: wrap;
}

.tabsPanels {
  margin-top: 24px;
}


.notVerified {
  font-size: 10px;
  color: white;
  padding: 0 4px;
  border: 1px solid white;
  border-radius: 4px;
  margin: 0 4px;
  white-space: nowrap;
}

.verified {
  font-size: 10px;
  color: var(--chakra-colors-success);
  padding: 0 4px;
  border: 1px solid var(--chakra-colors-success);
  border-radius: 4px;
  margin-left: 8px;
  white-space: nowrap;
}


