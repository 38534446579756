.dropdown {
  z-index: 1010;

  .arrow {
    border-bottom-color: inherit;
    border-left-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-top-color: transparent;
    border-width: 0 6px 6px;
    height: 0;
    width: 0;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -6px;
  }

  &[data-popper-placement^='top'] > .arrow {
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;

    border-top-color: inherit;
    border-width: 6px 6px 0;
    bottom: -6px;
  }

  &[data-popper-placement^='left'] > .arrow {
    border-bottom-color: transparent;
    border-left-color: inherit;
    border-right-color: transparent;

    border-top-color: transparent;
    border-width: 6px 0 6px 6px;
    right: -6px;
  }

  &[data-popper-placement^='right'] > .arrow {
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: inherit;

    border-top-color: transparent;
    border-width: 6px 6px 6px 0;
    left: -6px;
  }
}

.dropdownContent{
  padding: 12px 12px 24px;
  background: #191919;
  border-radius: 8px;
  min-width: 180px;
  backdrop-filter: blur(3px);
}

.heading {
  padding: 8px 10px;
  height: 44px;
  background-color: #232323;
  border-radius: 8px;
  border: 1px solid transparent;
  font-size: 16px;
  text-align: center;
  transition: all 0.2s ease;
  cursor: pointer;
  font-weight: 400;
  width: 100%;
  min-width: 180px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  color: rgb(255 255 255 / 50%);

  @media (width >= 180px) {
    width: fit-content;
  }

  &:hover {
    color: white;
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
  }
}

.mobileDropdown {
  height: 100dvh;
  z-index: 100000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  background-color: var(--chakra-colors-gray-13);
  border-radius: 12px 12px 0 0;

  > div {
    width: 100% !important;
  }
}


.icon {
  height: 16px;
  width: 16px;
}

.link {
  font-size: 16px;
  color: var(--chakra-colors-primaryWhite);
  cursor: pointer;
  transition: all 0.2s ease;
  padding-block: 8px;
  padding-inline: 8px;
  width: 100%;
  border-radius: 8px;

  &.active {
    color: var(--chakra-colors-orange);
  }

  &:hover {
    background-color: var(--chakra-colors-neutral-700);
  }
}

.mobileLink {
  padding: 8px 12px;
  background-color: var(--chakra-colors-neutral-800);
  margin-bottom: 4px;
}

.right {
  width: 100%;
}

.right > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
