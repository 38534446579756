@import "src/styles/mixins";
@import "src/styles/media";

.socialLink {
  @include centralize;
  padding: 8px;
  color: var(--chakra-colors-whiteOpacity-50);

  border: 1px solid var(--chakra-colors-whiteOpacity-6);
  border-radius: 3px;
}
