@use 'typography';
@use 'reset';
@use 'mixins';
@use 'colors';
@use 'media';
@import 'tippy.js/animations/shift-away.css';
@import 'react-loading-skeleton/dist/skeleton.css';

:root {
  --app-header-height: 100px;
  --app-footer-height: 320px;
  --font-space-grotesk: 'Space Grotesk Variable', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

html {
  background-color: var(--brand-black);
  color: var(--brand-white);
  font-family: var(--font-space-grotesk);
}

html,
body {
  @include mixins.custom-scrollbar-vertical;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  max-width: 100vw;
  width: 100%;
  min-height: 100vh;
}

p {
  color: var(--brand-white);
}

a {
  color: initial;
  text-decoration: none;
}

.Toastify__toast.Toastify__toast {
  padding: 0;
}

.Toastify__toast-body.Toastify__toast-body {
  padding: 0;
}
