.root {
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
}

.amount {
  font-size: 16px;
  color: white;
  font-weight: 500;
}

.usdAmount {
  font-size: 14px;
  color: var(--chakra-colors-gray-4);
}
