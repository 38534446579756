@import "src/styles/media";

.root.root {
  @include tablet-landscape {
    padding: 0;
  }
}

.wrapper.wrapper {
  @include tablet-landscape {
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    border-radius: 0 0 12px 12px;
    padding: 18px 20px;

    max-width: 1440px;
    margin: auto;
    width: 100%;
  }
}
