@import "src/styles/mixins";
@import "src/styles/media";

.root {
  grid-template-columns: 1fr;
  grid-gap: 84px;
  display: grid;
  padding: 34px 20px 20px;
  border-radius: 12px 12px 0 0;
  background: var(--chakra-colors-whiteOpacity-4);
  min-height: 258px;

  max-width: 1440px;
  margin: auto;
  width: 100%;

  @include desktop {
    grid-template-columns: 340px 1fr;
    grid-gap: 84px;
  }
}

.socialLink {
  @include centralize;
  padding: 8px;
  color: var(--chakra-colors-whiteOpacity-50);

  border: 1px solid var(--chakra-colors-whiteOpacity-6);
  border-radius: 3px;
}

.socialInfo {
  padding: 8px 14px;
  color: var(--chakra-colors-whiteOpacity-50);
  background-color: var(--chakra-colors-whiteOpacity-6);;
}

.topicTitle {
  font-size: 14px;
  line-height: 20px;
  color: var(--chakra-colors-white);
}


.nav {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;

  > div {
    flex: 1;
    max-width: 220px;
  }
}

.listItem {
  margin: 16px 0;
  align-items: center;
  display: flex;
  white-space: nowrap;
  color: var(--chakra-colors-whiteOpacity-50);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  > a {
    display: flex;
    align-items: center;
    color: var(--chakra-colors-whiteOpacity-50);

    svg:first-child {
      margin-right: 6px;
    }
  }
}
