@import 'src/styles/mixins';
@import 'src/styles/media';

.root {
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  min-height: calc(100vh - var(--app-header-height) - var(--app-footer-height));
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding-inline: 16px;

  @include desktop {
    padding-inline: unset;
  }
}
