@import '@styles/mixins';
@import '@styles/media';

.root {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  &.info {
    background: var(--chakra-colors-neutral-700);
    color: var(--chakra-colors-white);
  }

  &.warning {
    background: var(--chakra-colors-danger-200);
    color: var(--chakra-colors-black);
  }

  &.error {
    background: var(--chakra-colors-orange);
    color: var(--chakra-colors-white);
  }

  &.success {
    background: var(--chakra-colors-success);
    color: var(--chakra-colors-white);
  }
}

.icon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.body.body {
  padding: 16px;
  background: var(--chakra-colors-neutral-800);
  color: var(--chakra-colors-white);
  align-items: flex-start;
}

.closeBtn {
  min-width: unset;
  padding: 0;
}
