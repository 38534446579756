.auctionTypeDropdownHeading {
  width: 100% !important;
  background-color: var(--chakra-colors-neutral-1000);
}

.auctionTypeDropdownContainer {
  z-index: 121;
}

.tooltipTrigger {
  width: 100%;
}

.componentContainer {
  width: 100%;
}
.headingContainer {
  width: 100%;
}
