.tableContainer {
  border: 1px solid #1E1E1E;
  border-radius: 16px;
  padding: 0 24px 12px;
}

.tableHeader, .tableRow {
  grid-template-columns: minmax(120px, 1.1fr) repeat(4, minmax(80px, 0.6fr)) 120px;
  gap: 16px;
}

.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background-color: unset;
  display: grid;
  padding: 12px 16px 8px;
  color: #6F6F6F;
}

.tableHeader :global(.chakra-text) {
  color: #6F6F6F;
}

.tableRow {
  display: grid;
  padding: 4px 8px;
  border: 1px solid var(--chakra-colors-gray-5);
  border-radius: 8px;
  align-items: center;
  margin-bottom: 12px;
  white-space: nowrap;

  --cart-icon-color: var(--chakra-colors-gray-15);

  &:hover {
    cursor: pointer;
    background-color: var(--chakra-colors-gray-16);
    border-color: transparent;

    --cart-icon-color: white;

    .buyButtonRoot button {
      color: white;
      background-color: #6F6F6F4D;
    }
  }
}

.tableCell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scrollbar {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  padding: 24px;

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--chakra-colors-orange);
    border-radius: 17px;
  }

  &::-webkit-scrollbar-track {
    background-color: #232323;
  }

  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: #232323  var(--chakra-colors-orange);
  }
}
