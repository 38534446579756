.root {
  box-shadow: 0 0 4px 4px var(--chakra-colors-neutral-1000);
}

.main {
  transform: translateY(-8px);

  &::before {
    content: "";
    position: absolute;
    width: 96px;
    height: 38px;
    border-radius: 24px;
    background: #1a1a1a;
    z-index: -1;
    top: -24px;
    border-top: 1px solid #494949;
  }
}

.active {
  color: var(--chakra-colors-orange);
}
