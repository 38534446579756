:root {
  // brand
  --brand-black: #121212;
  --brand-white: #FAFAFC;
  --brand-orange: #FF4500;

  // common
  --black: #000;
  --white: #FFF;

  // gray
  --gray-1: #131313;
  --gray-2: #1A1A1A;

  // neutral
  --neutral-100: #E2E2E2;

  // opacity
  --white-opacity-04: rgb(255 255 255 / 4%);
  --white-opacity-06: rgb(255 255 255 / 6%);
  --white-opacity-10: rgb(255 255 255 / 10%);
  --white-opacity-50: rgb(255 255 255 / 50%);
}
